<template>
  <!-- 数据统计 -->
  <div class="visit-state">
    <div class="visitSearch">
      <div class="titleSearch">
        <div class="timeSty">
          <el-date-picker
            @change="timeChange"
            v-model="time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
        <el-input
          @keyup.enter.native="searchClick"
          clearable
          v-model="input"
          placeholder="场所名称/点位名称/UUID"
        ></el-input>
        <el-button
          @click="searchClick"
          class="titleButton"
          type="primary"
          icon="el-icon-search"
          >搜索</el-button
        >
        <el-button
          @click="exportClick"
          class="titleButton"
          icon="el-icon-download"
          :loading="exportLoad"
          >导出
          <a title="data"></a>
        </el-button>
      </div>
    </div>
    <div class="equipmentTableC">
      <div class="companyTable">
        <el-table
          v-loading="loading"
          :data="visitData.elements"
          style="width: 100%"
          height="100%"
        >
          <!-- 这里开始 -->
          <el-table-column prop="placeName" label="单位名称" min-width="160">
          </el-table-column>
          <el-table-column prop="contact" label="联系人" min-width="100">
          </el-table-column>
          <el-table-column
            prop="contactPhone"
            label="联系人电话"
            min-width="120"
          >
          </el-table-column>
          <el-table-column
            prop="mac"
            label="mac"
            align="center"
            min-width="160"
          >
          </el-table-column>
          <el-table-column prop="sn" label="SN" align="center" min-width="160">
          </el-table-column>
          <el-table-column
            prop="count"
            label="通过记录次数"
            align="center"
            min-width="120"
          >
          </el-table-column>
          <!-- <el-table-column
            prop="healthDecodeApiCount"
            label="解码接口次数"
            align="center"
            min-width="120"
          >
          </el-table-column> -->
          <!-- <el-table-column
            prop="deviceSceneDecodeApiCount"
            label="设备场所码接口次数"
            align="center"
            min-width="150"
          >
          </el-table-column> -->
          <!-- <el-table-column label="上传状态" align="center" min-width="80">
            <template slot-scope="scope">
              <i
                v-if="scope.row.pushStatus === 1"
                class="iconfont icon-check-circle-fill"
              ></i>
              <i
                v-if="scope.row.pushStatus === 0"
                class="iconfont icon-close-circle-fill"
              ></i>
            </template>
          </el-table-column> -->
          <!-- <el-table-column prop="pushTime" label="上传时间" min-width="160">
          </el-table-column> -->
        </el-table>
      </div>
      <el-pagination
        :current-page="currentPage"
        :page-size="visitData.pageSize"
        @current-change="handleCurrentChange"
        background
        layout="total, prev, pager, next"
        :total="visitData.totalRecords"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";

export default {
  name: "visit-state",
  components: {},
  data() {
    return {
      loading: false,
      // 导出 loading
      exportLoad: false,
      // 时间
      time: [],
      // 搜索值
      input: "",
      // 拉取到的 表格数据
      visitData: {},
      // 当前页数
      currentPage: 1,
      //列表-开始位置
      start: 1,
    };
  },
  created() {
    // 时间
    this.myTime();
    this.getVisit();
  },
  methods: {
    // 时间
    myTime() {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      // 开始时间
      this.startTime = dayjs(start)
        .startOf("day")
        .format("YYYY/MM/DD HH:mm:ss");
      // 结束时间
      this.endTime = dayjs(end).endOf("day").format("YYYY/MM/DD HH:mm:ss");
      this.time = [this.startTime, this.endTime];
    },
    // 时间-用户确认选定的值时触发
    timeChange() {
      if (this.time !== null) {
        // 开始时间
        let startTime = dayjs(this.time[0]).format("YYYY/MM/DD");
        // 结束时间
        let endTime = dayjs(this.time[1]).format("YYYY/MM/DD");
        this.time = [startTime, endTime];
      }
    },
    // 数据统计 列表
    getVisit() {
      this.loading = true;

      this.$instance
        .get("/v1/push/stat/list-page", {
          params: {
            pageNum: this.currentPage - this.start,
            pageSize: 10,
            start: this.time[0],
            end: this.time[1],
            // 搜索关键字 SN
            searchStr: this.input,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            this.loading = false;
            this.visitData = res.data.data;
          }
        })
        .catch((error) => {
          console.log("error: ", error);
        });
    },
    // 点击搜索
    searchClick() {
      this.currentPage = 1;
      // 如果没有时间选择 默认最近一个月
      if (!this.time) {
        this.time = [this.startTime, this.endTime];
      }
      this.getVisit();
    },
    // 点击分页-当前页
    handleCurrentChange(val) {
      this.currentPage = val;
      // 搜索并拉取列表
      this.getVisit();
    },
    // 点击导出
    exportClick() {
      // 如果没有时间选择 默认最近一个月
      if (!this.time) {
        this.time = [this.startTime, this.endTime];
      }
      this.getExport();
    },
    // 导出数据
    getExport() {
      this.exportLoad = true;
      this.$instance
        .get("/v1/push/stat/download", {
          params: {
            pageSize: 10000,
            start: this.time[0],
            end: this.time[1],
            // 搜索关键字 SN
            searchStr: this.input,
          },
          responseType: "arraybuffer",
        })
        .then((res) => {
          const blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          // IE
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, "data");
          } else {
            this.imatateDownloadByA(
              window.URL.createObjectURL(blob),
              "数据统计data"
            );
          }
          this.exportLoad = false;
        })
        .catch((error) => {
          console.log("error: ", error);
          this.exportLoad = false;
        });
    },
    //  @param {String} href
    //  @param {String} filename
    imatateDownloadByA(href, filename) {
      const a = document.createElement("a");
      a.download = filename;
      a.style.display = "none";
      a.href = href;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(href);
    },
  },
};
</script>
<style lang="scss" scoped>
.visit-state {
  width: calc(100% - 40px);
  margin: 20px;
  background-color: #fff;
  // 搜索
  & ::v-deep .visitSearch {
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0px 20px;
    box-sizing: border-box;
    border-bottom: 1px #f0f0f0 solid;
    .el-button--primary {
      line-height: 32px;
      padding: 0 12px;
      border: unset;
    }
    .titleSearch {
      display: flex;
      .titleButton {
        line-height: 30px;
        padding: 0 12px;
      }
      .timeSty {
        margin-right: 6px;
        .el-date-editor {
          width: 260px;
        }
        .el-input__icon,
        .el-range-separator {
          height: unset;
        }
      }
      .el-select > .el-input,
      .el-input {
        margin-right: 6px;
      }
      .el-select > .el-input {
        width: 150px;
      }
      .el-input__inner {
        height: 32px;
        line-height: 32px;
      }
      .el-input {
        width: 240px;
        .el-input__inner {
          height: 32px;
          line-height: 32px;
        }
        .el-input__suffix {
          i {
            line-height: 32px;
          }
        }
      }
    }
  }
  .equipmentTableC {
    width: calc(100% - 40px);
    height: calc(100% - 144px);
    margin: 20px;
    // 表格
    & ::v-deep .companyTable {
      height: 100%;
      border: 1px #f0f0f0 solid;
      overflow: auto;
      .icon-check-circle-fill {
        color: #52c41a;
      }
      .icon-close-circle-fill {
        color: #d81e06;
      }
    }
  }
}
</style>
